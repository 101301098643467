import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { CRUD } from '@chapo/shared-utils';
import { Feature } from '@chapo/users-data';
import { map, take } from 'rxjs';
import { AuthService } from '../services/auth.service';

export function hasUserPermissionGuard(feature: Feature, permissionType: keyof CRUD): CanMatchFn {
  return () => {
    const router = inject(Router);
    const authService = inject(AuthService);

    return authService.hasPermission(feature, permissionType).pipe(
      map((hasPermission) => {
        if (!hasPermission) {
          return router.parseUrl('/');
        }

        return true;
      }),
      take(1),
    );
  };
}
