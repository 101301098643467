import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { environment } from 'apps/chapo-cfdi-frontend/src/environments/environment';

export function backendInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  if (environment.name !== 'development' && req.url.startsWith('/backend')) {
    const url = environment.apiUrl + req.url.replace('/backend', '');
    const clonedRequest = req.clone({ url });

    return next(clonedRequest);
  }

  return next(req);
}
