import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map, take } from 'rxjs';
import { AuthService } from '../services/auth.service';

export function hasAdminUserRoleGuard(): CanMatchFn {
  return () => {
    const router = inject(Router);
    const authService = inject(AuthService);

    return authService.hasAdminRole$.pipe(
      map((isAdmin) => {
        if (!isAdmin) {
          return router.parseUrl('/');
        }

        return true;
      }),
      take(1),
    );
  };
}
