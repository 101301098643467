import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(isLeapYear);
dayjs.extend(isoWeeksInYear);
dayjs.extend(weekOfYear);

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

async function setupMSW() {
  if (true) {
    return;
  }

  const { worker } = await import('./mocks/browser');
  return worker.start({ onUnhandledRequest: 'bypass' });
}

async function bootstrapApp() {
  await setupMSW();

  return bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
}

bootstrapApp();
