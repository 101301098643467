import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { I18nService } from './i18n.service';

@Injectable({ providedIn: 'root' })
export class I18nTitleStrategy extends TitleStrategy {
  private titleService = inject(Title);
  private i18nService = inject(I18nService);
  appName = toSignal(this.i18nService.selectTranslate<string>('common.app_name'));

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    const appName = this.appName() ?? '';

    if (title !== undefined) {
      const i18nTitle = this.i18nService.translate(title);
      this.titleService.setTitle(i18nTitle ? `${appName} - ${i18nTitle}` : appName);
      this.i18nService.title.set(i18nTitle);
    }
  }
}
