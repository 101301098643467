import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withRouterConfig,
} from '@angular/router';
import { authInterceptor } from '@chapo/auth-data';
import { I18nService, provideI18N, provideI18nTitle } from '@chapo/shared-i18n';
import { provideQueryDevTools } from '@ngneat/query-devtools';
import { provideNzConfig } from 'ng-zorro-antd/core/config';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { first, switchMap, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { backendInterceptor } from './core/data-access/backend.interceptor';

export function initializeApp(i18nService: I18nService) {
  return () => {
    return i18nService.activeLang$.pipe(
      switchMap((lang) => i18nService.load(lang)),
      first(),
      tap(() => {
        i18nService.setActiveLang('es-MX');
      }),
    );
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    environment.name === 'production' ? [] : provideQueryDevTools({}),
    provideHttpClient(withInterceptors([backendInterceptor, authInterceptor])),
    provideAnimations(),
    provideI18N(),
    provideI18nTitle(),
    importProvidersFrom(NzModalModule),
    provideEnvironmentNgxMask(),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializeApp,
      deps: [I18nService],
    },
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withComponentInputBinding(),
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
    ),
    provideNzConfig({
      form: {
        nzAutoTips: {
          es: {},
          default: {},
        },
      },
    }),
  ],
};
