import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { filterSuccessResult } from '@ngneat/query';
import { map, take } from 'rxjs';
import { ProceduresService } from '../services/procedures.service';
import { Procedure } from './procedure.type';

export const procedureDetailResolver = <R>(projector: (procedure: Procedure) => R): ResolveFn<R> => {
  return (route) => {
    const proceduresService = inject(ProceduresService);

    return proceduresService.getById(route.params['procedureId']).result$.pipe(
      filterSuccessResult(),
      map((query) => projector(query.data)),
      take(1),
    );
  };
};
