import { Route } from '@angular/router';
import { alreadyAuthenticatedGuard, authGuard, hasAdminUserRoleGuard, hasUserPermissionGuard } from '@chapo/auth-data';
import { companyDetailResolver } from '@chapo/companies-data';
import { documentDetailResolver } from '@chapo/documents-data';
import { procedureDetailResolver } from '@chapo/procedures-data';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'documents',
  },
  {
    path: 'auth',
    canMatch: [alreadyAuthenticatedGuard()],
    loadComponent: () => import('../modules/auth/auth-shell/auth-shell.component'),
    children: [
      {
        path: 'login',
        loadComponent: () => {
          return import('../modules/auth/login-page/login-page.component');
        },
      },
      {
        path: 'forgot-password',
        loadComponent: () => {
          return import('../modules/auth/forgot-password-page/forgot-password-page.component');
        },
      },
      {
        path: 'reset-password',
        loadComponent: () => {
          return import('../modules/auth/reset-password-page/reset-password-page.component');
        },
      },
    ],
  },
  {
    path: '',
    canMatch: [authGuard()],
    loadComponent: () => import('./shell/shell.component'),
    children: [
      {
        path: 'dashboard',
        title: 'dashboard.title',
        loadComponent: () => {
          return import('../modules/dashboard/dashboard-page/dashboard-page.component');
        },
      },
      {
        path: 'companies',
        title: 'companies.title',
        data: {
          breadcrumb: 'companies.title',
        },
        loadComponent: () => {
          return import('../modules/companies/companies-page/companies-page.component');
        },
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'list' },
          {
            path: 'list',
            loadComponent: () => {
              return import('../modules/companies/containers/companies-list/companies-list-container.component');
            },
          },
          {
            path: ':companyId',
            resolve: {
              breadcrumb: companyDetailResolver((item) => item.name),
            },
            children: [
              { path: '', pathMatch: 'full', redirectTo: 'details' },
              {
                path: 'details',
                data: {
                  breadcrumb: 'companies.details.title',
                },
                loadComponent: () => {
                  return import('../modules/companies/containers/company-details/company-details-container.component');
                },
              },
              {
                path: 'bank-accounts',
                data: {
                  breadcrumb: 'bank_accounts.list.title',
                },
                loadComponent: () => {
                  return import(
                    '../modules/bank-accounts/containers/bank-accounts-list-container/bank-accounts-list-container.component'
                  );
                },
              },
              {
                path: 'files',
                data: {
                  breadcrumb: 'companies.files.list.title',
                },
                children: [
                  { path: '', pathMatch: 'full', redirectTo: 'list' },
                  {
                    path: 'list',
                    loadComponent: () => {
                      return import(
                        '../modules/companies/containers/company-files-list-container/company-files-list-container.component'
                      );
                    },
                  },
                  {
                    path: ':fileId',
                    resolve: {
                      companyFile: companyDetailResolver((item, route) => {
                        const fileId = Number(route.paramMap.get('fileId'));

                        if (!fileId) {
                          return undefined;
                        }

                        return item.files.find((file) => file.id === fileId);
                      }),
                      breadcrumb: companyDetailResolver((item, route) => {
                        const fileId = Number(route.paramMap.get('fileId'));

                        if (!fileId) {
                          return '';
                        }

                        return item.files.find((file) => file.id === fileId)?.name ?? '';
                      }),
                    },
                    children: [
                      { path: '', pathMatch: 'full', redirectTo: 'history' },
                      {
                        path: 'history',
                        data: {
                          breadcrumb: 'companies.file_history.title',
                        },
                        loadComponent: () => {
                          return import(
                            '../modules/companies/containers/company-file-history-list-container/company-file-history-list-container.component'
                          );
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'documents',
        title: 'documents.title',
        data: {
          breadcrumb: 'documents.title',
        },
        loadComponent: () => {
          return import('../modules/documents/documents-page/documents-page.component');
        },
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'list' },
          {
            path: 'list',
            loadComponent: () => {
              return import(
                '../modules/documents/containers/documents-list-container/documents-list-container.component'
              );
            },
          },
          {
            path: ':documentId',
            resolve: {
              breadcrumb: documentDetailResolver((item) => item.document_id),
            },
            children: [
              { path: '', pathMatch: 'full', redirectTo: 'details' },
              {
                path: 'details',
                data: {
                  breadcrumb: 'documents.details.title',
                },
                loadComponent: () => {
                  return import(
                    '../modules/documents/containers/document-details-container/document-details-container.component'
                  );
                },
              },
            ],
          },
        ],
      },
      {
        path: 'procedures',
        canMatch: [hasUserPermissionGuard('procedures', 'read')],
        title: 'procedures.title',
        data: {
          breadcrumb: 'procedures.title',
        },
        loadComponent: () => {
          return import('../modules/procedures/procedures-page/procedures-page.component');
        },
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'list' },
          {
            path: 'list',
            loadComponent: () => {
              return import(
                '../modules/procedures/containers/procedures-list-container/procedures-list-container.component'
              );
            },
          },
          {
            path: ':procedureId',
            resolve: {
              breadcrumb: procedureDetailResolver((item) => item.control_number),
            },
            children: [
              { path: '', pathMatch: 'full', redirectTo: 'details' },
              {
                path: 'details',
                data: {
                  breadcrumb: 'procedures.details.title',
                },
                loadComponent: () => {
                  return import(
                    '../modules/procedures/containers/procedure-details-container/procedure-details-container.component'
                  );
                },
              },
            ],
          },
        ],
      },
      {
        path: 'users',
        canMatch: [hasAdminUserRoleGuard()],
        title: 'users.title',
        data: {
          breadcrumb: 'users.title',
        },
        loadComponent: () => {
          return import('../modules/users/users-page/users-page.component');
        },
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'list' },
          {
            path: 'list',
            loadComponent: () => {
              return import('../modules/users/containers/users-list-container/users-list-container.component');
            },
          },
        ],
      },
      {
        path: 'tools',
        canMatch: [hasAdminUserRoleGuard()],
        title: 'tools.title',
        data: {
          breadcrumb: 'tools.title',
        },
        loadComponent: () => {
          return import('../modules/tools/tools-page/tools-page.component');
        },
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'evenly-distributed-numbers' },
          {
            path: 'evenly-distributed-numbers',
            data: {
              breadcrumb: 'tools.evenly_distributed_numbers.title',
            },
            loadComponent: () => {
              return import(
                '../modules/tools/components/evenly-distributed-numbers-generator/evenly-distributed-numbers-generator.component'
              );
            },
          },
          {
            path: 'consolidate-excel-files',
            data: {
              breadcrumb: 'tools.consolidate_excel_files.title',
            },
            loadComponent: () => {
              return import('../modules/tools/components/consolidate-excel-files/consolidate-excel-files.component');
            },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    loadComponent: () => import('./not-found/not-found-page.component'),
  },
];
