import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { filterSuccessResult } from '@ngneat/query';
import { map, take } from 'rxjs';
import { DocumentsService } from '../services/documents.service';
import { IDocument } from './document.type';

export const documentDetailResolver = <R>(projector: (document: IDocument) => R): ResolveFn<R> => {
  return (route) => {
    const documentsService = inject(DocumentsService);

    return documentsService.getById(route.params['documentId']).result$.pipe(
      filterSuccessResult(),
      map((query) => projector(query.data)),
      take(1),
    );
  };
};
