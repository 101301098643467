import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { map, take } from 'rxjs';
import { AuthService } from '../services/auth.service';

export function authGuard(): CanMatchFn {
  return (route: Route, segments: UrlSegment[]) => {
    const router = inject(Router);
    const authService = inject(AuthService);

    return authService.isAuthenticated$.pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          const requestedUrl = router.getCurrentNavigation()?.extractedUrl.toString();
          const returnUrl = requestedUrl === '/' ? undefined : requestedUrl;

          return router.createUrlTree(['/auth/login'], {
            queryParams: { returnUrl },
          });
        }

        return true;
      }),
      take(1),
    );
  };
}
