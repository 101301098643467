import { Provider, isDevMode } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { provideTransloco } from '@ngneat/transloco';
import { I18nTitleStrategy } from './i18n-title-strategy';
import { TranslocoHttpLoader } from './transloco-loader';

export function provideI18N() {
  return provideTransloco({
    config: {
      availableLangs: ['es-MX'],
      defaultLang: 'es-MX',
      // Remove this option if your application doesn't support changing language in runtime.
      reRenderOnLangChange: false,
      prodMode: !isDevMode(),
    },
    loader: TranslocoHttpLoader,
  });
}

export function provideI18nTitle(): Provider {
  return { provide: TitleStrategy, useClass: I18nTitleStrategy };
}
