import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { filterSuccessResult } from '@ngneat/query';
import { map, take } from 'rxjs';
import { CompaniesService } from '../services/companies.service';
import { Company } from './company.type';

export const companyDetailResolver = <R>(
  projector: (company: Company, route: ActivatedRouteSnapshot) => R,
): ResolveFn<R> => {
  return (route) => {
    const companiesService = inject(CompaniesService);

    return companiesService.getById(route.params['companyId']).result$.pipe(
      filterSuccessResult(),
      map((query) => projector(query.data, route)),
      take(1),
    );
  };
};
